// SASS Vars

$color_font: #F2F2F2;
$color_orange: #FFCC00;
$color_orange-dark: #FF9900;
$color_grey: #CCCCCC;
$color_blue_light: #0099FF;
$color_blue-dark: #0000CC;

$color_link-orange: $color_orange;
$color_link-orange_hover: $color_blue-dark;
$color_link-blue: $color_blue_light;
$color_link-blue_hover: $color_orange;
$color_link-grey: $color_grey;
$color_link-grey_hover: $color_blue-dark;

$color_infobox: rgba(0, 153, 255, 0.5);
$shadow_infobox: 2px 2px 8px 1px rgba(0, 0, 0, 0.3);





// DEFAULT OVERRIDES

body {
	background: url($baseurl + "/assets/img/background.jpg") no-repeat center center fixed;
	background-size: cover;
	text-size-adjust: none;
	word-wrap: break-word;
	font-family: Times New Roman;
	color: $color_font;
	font-size: 18px;

	>div>* {
		box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.3);
	}
}

table, th, td {
	border-collapse: collapse;
	border-spacing: 0;
	padding: 0;
}

a {
	color: $color_link_orange;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

hr {
	border-top: 1px solid rgb(90, 90, 90);
	border-bottom: 1px solid rgb(191, 191, 191);
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	text-decoration: underline;
}
h1 {
	margin: 0.4em;
	font-size: 2.67em;
}
h2 {
	margin: 0.4em;
	font-size: 2.0em;
}
h3 {
	margin: 0.4em;
	font-size: 1.6em;
}
h4 {
	margin: 0.4em;
	font-size: 1.45em;
}
h5 {
	margin: 0.4em;
	font-size: 1.33em;
}
h6 {
	margin: 0.4em;
	font-size: 1.18em;
}


:focus-visible {
	outline: 4px solid;
	outline-offset: 1px;
	-moz-outline-radius: 10px;
}
::-moz-focus-inner {
	border: 0;
}
:active {
	outline: none;
}





// SITE STRUCTURE

.alert {
	padding: 20px;
	color: white;
	margin-bottom: 15px;
	font-size: 0.9em;

	a {
		text-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px,
	}
}
.alertRed {
	background-color: #f44336;
}
.alertYellow {
	background-color: #ff9800;

	a {
		color: $color_link_blue;
	}
}
.alertGreen {
	background-color: #4CAF50;
}
.alertBlue {
	background-color: #2196F3;
}


header {
	background-image: url($baseurl + "/assets/img/color_purple.png");
	height: 150px;
	width: 100%;
	background-position: center;

	div {
		background-position: center;
		background-repeat: no-repeat;
	}
	.headerBg {
		background-image: url($baseurl + "/assets/img/header_text.png");
		width: 100%;
		height: 100%;
	}
	.headerFace {
		background-size: contain;
		width: 100px;
		height: 100%;
	}
	.headerFaceLeft {
		background-image: url($baseurl + "/assets/img/header_cicero.png");
		padding-left: 40px;
		float: left;
	}
	.headerFaceRight {
		background-image: url($baseurl + "/assets/img/header_nepos.png");
		padding-right: 20px;
		float: right;
	}
	.headerMobile {
		height: 100%;
		width: 100%;
		text-align: center;
	}
}

nav {
	background-image: url($baseurl + "/assets/img/color_blue.png");
	margin-top: 2px;
	padding: 1px;

	>hr {
		margin: 5px 0px 8px 0px;
	}
	>hr:last-child {
		margin: 6px 0px 0px 0px;
	}
	>ul {
		padding: 0px 5px;
		list-style-type: none;
		margin: auto;
		overflow: hidden;
	}
	li {
		float: left;

		a {
			color: $color_link_grey;

			&:hover {
				color: $color_link_grey_hover;
				text-decoration: none;
			}
		}
	}
}

main {
	display: block; // For IE, which doesn't know the "main" tag
	background-image: url($baseurl + "/assets/img/color_blue.png");
	text-align: center;
	padding: 30px 15px 50px 15px;
}

footer {
	margin-top: 2px;
	padding: 19px 0px;
	display: grid;
	grid-template-columns: 200px auto 200px;
	align-items: center;
	position: relative;
	text-align: center;
	font-size: 0.9em;
	color: $color_grey;
	background-image: url($baseurl + "/assets/img/color_purple.png");

	/*>div:nth-child(2) a {
		color: $color_link_blue;

		&:hover {
			color: $color_link_blue_hover;
		}
	}*/
	.copyright {
		color: #ffffff;
		font-size: 1.12em;
		margin-bottom: 10px;

		a {
			color: #ffffff !important;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	.nav_btm {
		li {
			display: inline-block;
			font-size: 1.12em;

			a {
				color: $color_grey;
				text-align: center;
				padding-left: 5px;
				padding-right: 5px;
				text-decoration: none;

				&:hover {
					color: $color_link_grey_hover;
				}
			}
		}
	}
}





// DIV TABLES

div.table {
	display: table;
	border-collapse: collapse;
	width: 100%;
}
div.tr { 
	display:table-row; 
}
div.td { 
	display:table-cell;
}





// CUSTOM CLASSES

.blueBg {
	background-image: url($baseurl + "/assets/img/color_blue.png");
}
.purpleBg {
	background-image: url($baseurl + "/assets/img/color_purple.png");
}

.big1 {
	margin: 0.4em;
	font-size: 2.67em;
}
.big2 {
	margin: 0.4em;
	font-size: 2.0em;
}
.big3 {
	margin: 0.4em;
	font-size: 1.6em;
}
.big4 {
	margin: 0.4em;
	font-size: 1.45em;
}
.big5 {
	margin: 0.4em;
	font-size: 1.33em;
}
.big6 {
	margin: 0.4em;
	font-size: 1.18em;
}

.button {
	padding: 5px;
	border: 2px solid $color_orange;
	border-radius: 5px;
	display: inline-block;

	&:hover {
		text-decoration: none;
		color: $color_font;
		border: 2px solid $color_font;
	}
}

.infobox {
	max-width: 900px;
	margin: auto;
	margin-bottom: 20px;
	padding: 4px;
	text-align: left;
	border-radius: 10px;
	background-color: $color_infobox;
	box-shadow: $shadow_infobox;

	&::after {
		content: "";
		clear: both;
		display: block;
	}

	&__picture {
		margin: 2px 7px 2px 2px;
		width: 120px;
		height: 165px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		float: left;
		border-radius: 5px;
		box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
	}

	&__right {
		&__title {
			padding: 0px 5px 0px 5px;
			margin-top: 0px;

			hr {
				display: block;
				margin: 0px -7px;
				border-top: 1px solid rgb(90, 90, 90);
				border-bottom: 1px solid rgb(191, 191, 191);
			}
			p {
				margin: auto;
			}
		}
		&__content {
			padding: 0px 5px;
			color: rgb(215, 215, 215);
			text-align: justify;
			line-height: 22px;

			p {
				margin: auto;
				padding-top: 3px;
			}
		}
	}
}

.sectionLinksTranslation {
	font-weight: bold;

	ul {
		padding: 0;
	}
	li {
		display: inline-block;
		margin: 0px 10px 10px 10px;
	}
	a {
		color: $color_link_orange;
		text-decoration: none;

		&:hover {
			color: $color_link_orange_hover;
		}
	}
}

.idReferrer {
	margin: 0px auto 60px auto;
	max-width: 80%;

	ul {
		margin-top: 0px;
		padding: 0px;
	}	
	li {
		display: inline-block;
	}
	a {
		color: $color_link_orange;
		padding: 0px 5px;
		text-decoration:none;

		&:hover {
			color: $color_link_orange_hover;
		}
	}
}



.chapter {
	display: grid;

	&:first-child {
		h2 {
			margin-top: 15px;
		}
	}

	p {
		margin: unset;
	}
	h2 {
		color: $color_orange;
		grid-column: 1/10;
		margin-bottom: 10px;
	}
	sup {
		font-size: 0.75em;
		color: $color_orange-dark;
	}
	.annotation {
		color: $color_grey;
	}
	.story {
		box-sizing: border-box;
		grid-column: 1/10;
		margin: 12px auto;
		width: 100%;
		max-width: 1000px;
		padding: 6px;
		border-radius: 10px;
		background-color: $color_infobox;
		box-shadow: $shadow_infobox;
	}

	.chapter-item {
		grid-column: 1/10;
		max-width: 1000px;
		margin: auto;
		display: grid;
		grid-template-columns: 49% 2% 49%;

		p {
			text-align: left;
		}
	}
	.poem-item {
		grid-column: 1/10;
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 43px 1px 40% 1px auto;
		text-align: center;
		margin-left: -15px;
		margin-right: -15px;

		&:last-child {
			margin-bottom: 10px;
		}
		>div:nth-child(even) {
			background-position: fixed;
			background: url($baseurl + "/assets/img/background.jpg") no-repeat center center fixed;
		}
		p {
			text-align: inherit;

			&:nth-child(1) {
				color: $color_orange-dark;
			}
			&:not(:nth-child(1)) {
				padding: 0px 25px;
			}
		}
	}
	.verse-seperator {
		height: 30px;
	}
	.verse-missing {
		margin: 20px 0px;
		height: 1px;
		background-position: fixed;
		background: url($baseurl + "/assets/img/background.jpg") no-repeat center center fixed;
	}

	&:last-child {
		.poem-item:last-child {
			margin-bottom: -50px;

			p:not(:first-child) {
				padding-bottom: 50px;
			}
		}
	}
}
.footnotes {
	color: $color_grey;
	font-size: 0.89em;
	text-align: left;

	sup {
		color: $color_orange-dark;
	}
}



.translation-sources {
	margin-top: 2px;
	padding: 22px 10px;
	background-image: url($baseurl + "/assets/img/color_blue.png");
	text-align: center;

	/*a {
		color: $color_link_blue;

		&:hover {
			color: $color_link_blue_hover;
		}
	}*/
}

.comments {
	margin-top: 2px;
	padding: 10px 10px;
	background-image: url($baseurl + "/assets/img/color_blue.png");
	text-align: center;

	.disqus {
		max-width: 1050px;
		margin: auto;
	}
}







.hofItem {
	max-width: 640px;
	display: grid;
	grid-template-rows: 1fr auto 30px;
	text-align: center;
	color: #666666;
	border-radius: 35px;

	div:first-child {
		margin-bottom: 10px;
		font-size: 1.5em;
		font-weight: bold;
		border-bottom: groove 2px;
	}
}


// HELPER CLASSES

.helperVerticalAlignment {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}


.ShowOnSmallScreen {
	display: none;
}
.ShowOnBigScreen {
	display: block;
}

/*@media only screen and (max-width: 1132px) {
	.trlPoem tr td:nth-child(even) {
		max-width: 0px;
		min-width: 0px;
	}
}*/
@media only screen and (max-width: 1000px) {
	footer {
		grid-template-columns: 1fr auto auto 1fr;

		>div {
			&:nth-child(1), &:nth-child(3) {
				margin-bottom: 15px;
			}
			&:nth-child(1) {
				grid-column: 2/3;
				margin-right: 18px;
			}
			&:nth-child(3) {
				grid-column: 3/4;
				margin-left: 18px;
			}
			&:nth-child(4) {
				grid-column: 2/4;
			}
		}
	}
	.chapter {
		.chapter-item {
			grid-template-columns: 43% 2% 55%;
		}
		.poem-item {
			p {
				&:not(:nth-child(1)) {
					padding: 0px 30px;
				}
			}
		}
		.verse-seperator {
			height: 45px;
		}
	}
	
	.ShowOnSmallScreen {
		display: block;
	}
	.ShowOnBigScreen {
		display: none;
	}
}
@media only screen and (max-width: 800px) {
	.chapter {
		.poem-item {
			p {
				&:not(:nth-child(1)) {
					padding: 0px 20px;
				}
			}
		}
	}
}
@media only screen and (max-width: 400px) {
	footer {
		grid-template-columns: 1fr 1fr;

		>div {
			&:nth-child(1), &:nth-child(3) {
				margin-bottom: 15px;
			}
			&:nth-child(1) {
				grid-column: 1/2;
				margin-right: 0px;
			}
			&:nth-child(3) {
				grid-column: 2/3;
				margin-left: 0px;
			}
			&:nth-child(4) {
				grid-column: 1/3;
			}
		}
	}
	.chapter {
		.poem-item {
			p {
				&:not(:nth-child(1)) {
					padding: 0px 10px;
				}
			}
		}
	}
}

@media only screen and (max-width: 300px) {
	footer {
		grid-template-columns: 1fr;

		>div {
			&:nth-child(1) {
				grid-column: 1/2;
				margin-bottom: 5px;
			}
			&:nth-child(3) {
				grid-column: 1/2;
				margin-bottom: 10px;
			}
			&:nth-child(4) {
				grid-column: 1/2;
			}
		}
	}
	.chapter {
		.poem-item {
			grid-template-columns: unset;

			p {
				&:not(:nth-child(1)) {
					padding: 0px 5px;
				}
			}
		}
	}
}





.closebtn {
	margin-right: -10px;
	padding: 3px 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 2em;
	cursor: pointer;

	&:hover {
		color: black;
	}
}



.spc {
	float: left;
	color: $color_grey;
	text-align: center;
	padding: 0 5px;
	text-decoration: none;
}


.navRight {
	float: right;
	color: $color_grey;

	li:not(:last-child) {
		margin-right: 5px;
	}
}


.li ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
.li li a {
	float: center;
	text-align: center;
}
.li li a:hover {
	color: #0000CC;
}






.background {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 125%; /* To compensate for mobile browser address bar space */
	background: url($baseurl + "/assets/img/background.jpg") no-repeat center center fixed;
}


// OVERRIDE CLASSES
